var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between"
  }, [_c('ul', {
    staticClass: "flex"
  }, [_c('li', [_c('button', {
    staticClass: "w-8 h-8 flex justify-center items-center focus:outline-none",
    class: {
      'opacity-30 cursor-not-allowed': _vm.currentPage === 1
    },
    attrs: {
      "disabled": _vm.currentPage === 1
    },
    on: {
      "click": function ($event) {
        return _vm.onPrevious();
      }
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left"
    }
  })], 1)]), _vm._l(_vm.pages, function (page, index) {
    return _c('li', {
      key: 'pages' + index
    }, [page !== _vm.DOTS ? _c('button', {
      staticClass: "cursor-pointer mx-1 w-8 h-8 flex justify-center items-center rounded font-semibold focus:outline-none",
      class: [_vm.currentPage === page ? 'hover:bg-primary-500 bg-primary-500 text-white' : ' bg-transparent text-neutral-700 hover:bg-gray-300'],
      attrs: {
        "disabled": _vm.currentPage === page
      },
      on: {
        "click": function ($event) {
          return _vm.goToPage(page);
        }
      }
    }, [_vm._v(" " + _vm._s(page) + " ")]) : _c('span', {
      staticClass: "font-semibold inline-block mx-1"
    }, [_vm._v(_vm._s(page))])]);
  }), _c('li', [_c('button', {
    staticClass: "w-8 h-8 flex justify-center items-center focus:outline-none",
    class: {
      'opacity-30 cursor-not-allowed': _vm.currentPage === _vm.atLastPage
    },
    attrs: {
      "disabled": _vm.currentPage === _vm.atLastPage
    },
    on: {
      "click": function ($event) {
        return _vm.onNext();
      }
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "right"
    }
  })], 1)])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }